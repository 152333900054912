import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import bugbountyimg from "../../assets/Image/4186636.jpg";
import "./BugBounty.scss";
const BugBounty = () => {
    return (
        <>
            <Header />
            <section className="animateHeader17 extHeader cid-thLdt20yWi mbr-fullscreen" id="animateHeader17-7b">
                <div className="container coursescontainer">
                    <div className="row align-items-center">
                        <div className="col-md-6 align-left img-block py-md-0 pb-4">
                            <div className="mbr-figure">
                                <img src={bugbountyimg} alt="ctf" title="" className="bugbountyimg" />
                            </div>
                        </div>
                        <div className="mbr-white col-md-6 py-md-0 pt-2">
                            <h4 className="mbr-section-subtitle mbr-fonts-style align-left pb-2 display-5"><strong>Bug Bounty</strong></h4>
                            <h1 className="mbr-section-title mbr-white mbr-bold mbr-fonts-style align-left display-1">Start Hunting</h1>
                            <p className="mbr-text pb-3 mbr-fonts-style mbr-white align-left">
                                From challenges (web, crypto, reversing, forensics, etc.) to full-pwn machines and AD labs, it's all here! Join a public CTF or organize one for your team, event, conference, university, or company.</p>
                            <div className="mbr-section-btn"><a herf="/" className="btn btn-lg btn-primary display-4">Start Now</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="main container">
                    <h2 className="mbr-section-title pb-3 mbr-fonts-style mbr-bold align-left display-2">OUR HISTORY
                    </h2>
                    <div className="row">
                        <div className="first-col col-md-6">
                            <h3 className="mbr-section-subtitle mbr-fonts-style align-left mbr-regular display-7"><strong>Lorem ipsum dolor</strong> sit amet, consectetur adipisicing elit. Aut id, atque sed aperiam laborum nostrum quo fugit esse dolorum accusantium, nam maxime architecto hic magni harum blanditiis enim aliquam similique! Incidunt aliquam, temporibus labore provident expedita quod assumenda. Illum repellat itaque, modi assumenda atque voluptas! Repellat et facilis, fuga cupiditate ipsa, earum, aliquid molestiae quo, asperiores eaque consectetur deserunt aliquam.
                            </h3>
                            <div className="mbr-section-btn align-left py-2"><a className="btn btn-md btn-primary display-4" href="/">LEARN MORE</a></div>
                        </div>

                        <div className="second-col col-md-6">
                            <div className="carousel slide slides" role="listbox" data-pause="true" data-keyboard="false" data-ride="carousel" data-interval="3000">
                                <ol className="carousel-indicators">
                                    <li data-slide-to="0" className="active"></li>
                                    <li data-slide-to="1"></li>
                                    <li data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item ">
                                        <div className="media-container-row">
                                            <div className="col-md-12">
                                                <div className="wrap-img ">
                                                    <img src={bugbountyimg} className="img-responsive clients-img" alt="" title="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className="carousel-item ">
                                        <div className="media-container-row">
                                            <div className="col-md-12">
                                                <div className="wrap-img ">
                                                    <img src={bugbountyimg} className="img-responsive clients-img" alt="" title="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className="carousel-item ">
                                        <div className="media-container-row">
                                            <div className="col-md-12">
                                                <div className="wrap-img ">
                                                    <img src={bugbountyimg} className="img-responsive clients-img" alt="" title="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default BugBounty;