import './client.scss';
import clientlogo from '../../assets/Image/client-1.png';
const Client = () => {
    return (
        <>
            <section id="clients" className="clients">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                    <h2>Our Clients</h2>
                    <p>Temporibus omnis officia</p>
                    </header>

                    <div className="clients-slider swiper">
                    <div className="swiper-wrapper align-items-center">
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                        <div className="swiper-slide"><img src={clientlogo} className="img-fluid" alt=""/></div>
                    </div>
                    <div className="swiper-pagination"></div>
                    </div>
                </div>

            </section>
        </>
    );
};

export default Client;