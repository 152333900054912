import './header.scss'
import logo from '../../assets/Image/attackbox.png';
const Header = () => {
  return (
    <>
    <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center justify-content-between">

      <a href="/" className="logo"><img src={logo} alt="" className="img-fluid"/></a>

      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto" href="/CTFs">CTFs</a></li>
          <li><a className="nav-link scrollto" href="/Courses">Courses</a></li>
          <li><a className="nav-link scrollto" href="/BugBounty">BugBounty</a></li>
          <li className="dropdown"><a href="#"><span>Services</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="/Hackers">For Hackers</a></li>
              <li><a href="/Business">For Business</a></li>
              <li><a href="/University">For University</a></li>
            </ul>
          </li>
          <li className="dropdown"><a href="#"><span>Resources</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="/Blog">Blog</a></li>
              <li><a href="/Forum">Forum</a></li>
              <li><a href="/Community">Community</a></li>
              <li><a href="/InfosecCareer">InfosecCareer</a></li>
              <li><a href="/Events">Events</a></li>
              <li><a href="/Swag">Swag</a></li>
            </ul>
          </li>
          <li><a className="nav-link scrollto" href="/Price">Subscription</a></li>
          <li><a className="nav-link scrollto" href="/ContactUs">Contact</a></li>
          <li><a className="getstarted scrollto" href="/SignUp">Get Started</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
  </>
  )
}

export default Header;