import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import ContactUs from './Pages/ContectUs/ContactUs';
import Price from './Pages/Price/Price';
import Blog from './Pages/Blog/Blog';
import BlogSingle from './Pages/Blog/BlogSingle';
import Swag from './Pages/Swag/Swag';
import SwagDetail from './Pages/Swag/SwagDetail';
import Events from './Pages/Events/Events';
import CTFs from './Pages/CTFs/CTFs';
import Community from './Pages/Community/Community';
import InfosecCareer from './Pages/InfosecCareer/InfosecCareer';
import BugBounty from './Pages/BugBounty/BugBounty';
import SignUp from './Pages/SignUp/SignUp';
import Forum from './Pages/Forum/Forum';
import Courses from './Pages/Courses/Courses';
import Hackers from './Pages/Hackers/Hackers';
import Business from './Pages/Business/Business';
import University from './Pages/University/University';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="ContactUs" element={<ContactUs />}/>
            <Route path="Price" element={<Price />} />
            <Route path="Events" element={<Events />} />
            <Route path="CTFs" element={<CTFs />} />
            <Route path="Community" element={<Community />} />
            <Route path='Blog' element={<Blog />} />
            <Route path='blog/1' element={<BlogSingle />} />
            <Route path='Swag' element={<Swag />} />
            <Route path='Swag/:id' element={<SwagDetail />} />
            <Route path='InfosecCareer' element={<InfosecCareer />} />
            <Route path='BugBounty' element={<BugBounty />} />
            <Route path='SignUp' element={<SignUp />} />
            <Route path='Forum' element={<Forum />} />
            <Route path='Courses' element={<Courses />} />
            <Route path='Hackers' element={<Hackers />} />
            <Route path='Business' element={<Business />} />
            <Route path='University' element={<University />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
