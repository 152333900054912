import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import communityimg from "../../assets/Image/36.jpg";
import "./Community.scss";
const Community = () => {
    return (
        <>
            <Header />

            <section className="container mt-5" id="animateFooter8-4m">
                <div className="container slideInUp 1 text-center">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="plan-title mbr-section-title mbr-fonts-style display-3">Join AttackBox<br />Community</h1>
                    </div>
                    <div className="row social-links mt-3 h4">
                        <ul className="d-flex justify-content-center align-items-center">
                            <li className='evemt-li'>
                                <a href="twitter" className="twitter"><i className="bi bi-twitter"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="facebook" className="facebook"><i className="bi bi-facebook"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="instagram" className="instagram"><i className="bi bi-instagram"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="linkedin" className="linkedin"><i className="bi bi-linkedin"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a className="icon-transition" href="/">
                                    <span className="mbr-iconfont socicon-rss socicon" ></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="row"></div>
                </div>
            </section>

            <section className="cid-thLOtfXypf" id="animateContent18-7w">
                <div className="container align-center">
                    <div className="row">
                        <div className="col-lg-6 communitydiv col-md-12 align-center">
                            <img src={communityimg} alt="attackbox" />
                            <h1 className="mbr-section-title mbr-regular pt-5 align-left mbr-fonts-style display-3">Solutions For Your Business</h1>
                            <p className="mbr-text mbr-light pt-4 align-left mbr-fonts-style display-7">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui enim, pharetra at auctor vitae,
                                mollis nec risus. Phasellus sagittis lectus at suscipit pulvinar.
                            </p>
                            <div className="align-left mbr-section-btn"><a className="btn btn-md btn-primary display-4" href="/">READ MORE</a></div>
                        </div>

                        <div className="col-md-12 col-lg-6 communitydiv m-auto">
                            <h1 className="mbr-section-title mbr-regular pt-5 align-left mbr-fonts-style display-3">Innovative Technology</h1>
                            <p className="mbr-text mbr-light pt-4 align-left mbr-fonts-style display-7">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui enim, pharetra at auctor vitae,
                                mollis nec risus. Phasellus sagittis lectus at suscipit pulvinar.
                            </p>
                            <div className="align-left mbr-section-btn"><a className="btn btn-md btn-primary display-4" href="/">READ MORE</a></div>
                            <div className="pt-5">
                                <img src={communityimg} alt="attackbox" />
                            </div>
                        </div>

                        <div className="col-lg-6 communitydiv col-md-12 align-center">
                            <img src={communityimg} alt="attackbox" />
                            <h1 className="mbr-section-title mbr-regular pt-5 align-left mbr-fonts-style display-3">Solutions For Your Business</h1>
                            <p className="mbr-text mbr-light pt-4 align-left mbr-fonts-style display-7">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui enim, pharetra at auctor vitae,
                                mollis nec risus. Phasellus sagittis lectus at suscipit pulvinar.
                            </p>
                            <div className="align-left mbr-section-btn"><a className="btn btn-md btn-primary display-4" href="/">READ MORE</a></div>

                        </div>

                        <div className="col-md-12 col-lg-6 communitydiv m-auto">
                            <h1 className="mbr-section-title mbr-regular pt-5 align-left mbr-fonts-style display-3">Innovative Technology</h1>
                            <p className="mbr-text mbr-light pt-4 align-left mbr-fonts-style display-7">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui enim, pharetra at auctor vitae,
                                mollis nec risus. Phasellus sagittis lectus at suscipit pulvinar.
                            </p>
                            <div className="align-left mbr-section-btn"><a className="btn btn-md btn-primary display-4" href="/">READ MORE</a></div>
                            <div className="pt-5">
                                <img src={communityimg} alt="attackbox" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Community;