import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import "./InfosecCareer.scss";
import careerimg from "../../assets/Image/mbr-860x573.jpg";
const InfosecCareer = () => {
    return (
        <>
            <Header />
            <section className="extHeader" id="animateHeader21-78">
                <div className="container align-center mt-5">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 py-4 m-auto">
                            <h1 className="mbr-section-title mbr-regular pb-3 align-left mbr-fonts-style display-3">CyberSecurity<br/>Jobs</h1>

                            <p className="mbr-text mbr-light pb-3 align-left mbr-fonts-style display-7">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui enim, pharetra at auctor vitae,
                                mollis nec risus. Phasellus sagittis lectus at suscipit pulvinar.</p>
                            <div className="align-left mbr-section-btn"><a className="btn btn-md btn-primary display-4" href="/">READ MORE</a></div>
                        </div>

                        <div className="col-lg-6 col-md-12 align-center">
                            <div className="img-box">
                                <img src={careerimg} alt="InfosecCareer" className="infosecimg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="extFooter" id="animateFooter8-85">
                <div className="container">
                    <div className="col-md-12 col-sm-12 ">
                        <h1 className="plan-title fw-bold mbr-section-title mbr-fonts-style display-5">Upcoming Events</h1>
                    </div>
                    <div>
                        <table className="table">
                            <thead className="bg-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Position</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt="" className="rounded-circle user-icon" />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">John Doe</p>
                                                <p className="text-muted mb-0">john.doe@gmail.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">Software engineer</p>
                                        <p className="text-muted mb-0">IT department</p>
                                    </td>
                                    <td>
                                        <span className="badge badge-success rounded-pill d-inline">Active</span>
                                    </td>
                                    <td>Senior</td>
                                    <td>
                                        <button type="button" className="btn btn-link btn-sm btn-rounded">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img src="https://mdbootstrap.com/img/new/avatars/6.jpg" className="rounded-circle user-icon" alt="" />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">Alex Ray</p>
                                                <p className="text-muted mb-0">alex.ray@gmail.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">Consultant</p>
                                        <p className="text-muted mb-0">Finance</p>
                                    </td>
                                    <td>
                                        <span className="badge badge-primary rounded-pill d-inline">Onboarding</span>
                                    </td>
                                    <td>Junior</td>
                                    <td>
                                        <button type="button" className="btn btn-link btn-rounded btn-sm fw-bold" data-mdb-ripple-color="dark">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img src="https://mdbootstrap.com/img/new/avatars/7.jpg" className="rounded-circle user-icon" alt="" />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">Kate Hunington</p>
                                                <p className="text-muted mb-0">kate.hunington@gmail.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">Designer</p>
                                        <p className="text-muted mb-0">UI/UX</p>
                                    </td>
                                    <td>
                                        <span className="badge badge-warning rounded-pill d-inline">Awaiting</span>
                                    </td>
                                    <td>Senior</td>
                                    <td>
                                        <button type="button" className="btn btn-link btn-rounded btn-sm fw-bold" data-mdb-ripple-color="dark">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default InfosecCareer;