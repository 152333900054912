import './home.scss';
import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';
import Testimonial from '../../Component/Testimonial/Testimonial';
import Team from '../../Component/Team/Team';
import FAQ from '../../Component/FAQ/FAQ';
import Client from '../../Component/Client/Client';
import Hero_img from '../../assets/Image/3849221.png';
import Feature_img from '../../assets/Image/features.png';
import Feature_img2 from '../../assets/Image/features-2.png';
import Feature_img3 from '../../assets/Image/features-3.png';
import About_img from '../../assets/Image/about.png';
import Blog1 from '../../assets/blog/blog-1.jpg';
import Blog2 from '../../assets/blog/blog-2.jpg';
import Blog3 from '../../assets/blog/blog-3.jpg';
const Home = () => {
    return (
        <div>
            <Header />
            <section id="hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1>India's First Biggest Hacking Platform</h1>
                            <h2>Join us now and experience the gamified version of hacking. Improve your hacking skill on our platform with solving machine in our labs. Make your career to the next level.</h2>
                            <div className="d-flex">
                                <a href="/signup" className="btn-get-started scrollto">Get Started</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img">
                            <img src={Hero_img} className="img-fluid animated" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="featured-services" className="featured-services">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box">
                        <div className="icon"><i className="bi bi-laptop"></i></div>
                        <h4 className="title"><a href="">Lorem Ipsum</a></h4>
                        <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                        <div className="icon-box">
                        <div className="icon"><i className="bi bi-card-checklist"></i></div>
                        <h4 className="title"><a href="">Dolor Sitema</a></h4>
                        <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                        <div className="icon-box">
                        <div className="icon"><i className="bi bi-clipboard-data"></i></div>
                        <h4 className="title"><a href="">Sed ut perspiciatis</a></h4>
                        <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id="about" className="about">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6">
                        <img src={About_img} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                        <h3>Voluptatem dignissimos provident quasi corporis voluptates</h3>
                        <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                        </p>
                        <ul>
                        <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                        <li><i className="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
                        <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda</li>
                        </ul>
                        <p>
                        Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </div>
                    </div>
                </div>
            </section>
            <section id="counts" className="counts">
                <div className="container">
                    <div className="row counters">
                    <div className="col-lg-3 col-6 text-center">
                        <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
                        <p>Clients</p>
                    </div>
                    <div className="col-lg-3 col-6 text-center">
                        <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
                        <p>Projects</p>
                    </div>
                    <div className="col-lg-3 col-6 text-center">
                        <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" className="purecounter"></span>
                        <p>Hours Of Support</p>
                    </div>
                    <div className="col-lg-3 col-6 text-center">
                        <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter"></span>
                        <p>Hard Workers</p>
                    </div>
                    </div>
                </div>
            </section>
            <section id="features" className="features">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                    <h2>Features</h2>
                    <p>Laboriosam et omnis fuga quis dolor direda fara</p>
                    </header>
                    <div className="row">
                    <div className="col-lg-6">
                        <img src={Feature_img} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                        <div className="row align-self-center gy-4">
                        <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                            <div className="feature-box d-flex align-items-center">
                            <i className="bi bi-check"></i>
                            <h3>Eos aspernatur rem</h3>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                            <div className="feature-box d-flex align-items-center">
                            <i className="bi bi-check"></i>
                            <h3>Facilis neque ipsa</h3>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                            <div className="feature-box d-flex align-items-center">
                            <i className="bi bi-check"></i>
                            <h3>Volup amet voluptas</h3>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                            <div className="feature-box d-flex align-items-center">
                            <i className="bi bi-check"></i>
                            <h3>Rerum omnis sint</h3>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                            <div className="feature-box d-flex align-items-center">
                            <i className="bi bi-check"></i>
                            <h3>Alias possimus</h3>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                            <div className="feature-box d-flex align-items-center">
                            <i className="bi bi-check"></i>
                            <h3>Repellendus mollitia</h3>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row feture-tabs" data-aos="fade-up">
                    <div className="col-lg-6">
                        <h3>Neque officiis dolore maiores et exercitationem quae est seda lidera pat claero</h3>
                        <ul className="nav nav-pills mb-3">
                        <li>
                            <a className="nav-link active" data-bs-toggle="pill" href="#tab1">Saepe fuga</a>
                        </li>
                        <li>
                            <a className="nav-link" data-bs-toggle="pill" href="#tab2">Voluptates</a>
                        </li>
                        <li>
                            <a className="nav-link" data-bs-toggle="pill" href="#tab3">Corrupti</a>
                        </li>
                        </ul>
                        <div className="tab-content">
                        <div className="tab-pane fade show active" id="tab1">
                            <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                            <div className="d-flex align-items-center mb-2">
                            <i className="bi bi-check2"></i>
                            <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                            </div>
                            <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                            <div className="d-flex align-items-center mb-2">
                            <i className="bi bi-check2"></i>
                            <h4>Incidunt non veritatis illum ea ut nisi</h4>
                            </div>
                            <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                        </div>
                        <div className="tab-pane fade show" id="tab2">
                            <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                            <div className="d-flex align-items-center mb-2">
                            <i className="bi bi-check2"></i>
                            <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                            </div>
                            <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                            <div className="d-flex align-items-center mb-2">
                            <i className="bi bi-check2"></i>
                            <h4>Incidunt non veritatis illum ea ut nisi</h4>
                            </div>
                            <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                        </div>
                        <div className="tab-pane fade show" id="tab3">
                            <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                            <div className="d-flex align-items-center mb-2">
                            <i className="bi bi-check2"></i>
                            <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                            </div>
                            <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                            <div className="d-flex align-items-center mb-2">
                            <i className="bi bi-check2"></i>
                            <h4>Incidunt non veritatis illum ea ut nisi</h4>
                            </div>
                            <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img src={Feature_img2} className="img-fluid" alt=""/>
                    </div>
                    </div>
                    <div className="row feature-icons" data-aos="fade-up">
                    <h3>Ratione mollitia eos ab laudantium rerum beatae quo</h3>
                    <div className="row">
                        <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                        <img src={Feature_img3} className="img-fluid p-4" alt=""/>
                        </div>
                        <div className="col-xl-8 d-flex content">
                        <div className="row align-self-center gy-4">
                            <div className="col-md-6 icon-box" data-aos="fade-up">
                            <i className="ri-line-chart-line"></i>
                            <div>
                                <h4>Corporis voluptates sit</h4>
                                <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                            </div>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                            <i className="ri-stack-line"></i>
                            <div>
                                <h4>Ullamco laboris nisi</h4>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                            </div>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i className="ri-brush-4-line"></i>
                            <div>
                                <h4>Labore consequatur</h4>
                                <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                            </div>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                            <i className="ri-magic-line"></i>
                            <div>
                                <h4>Beatae veritatis</h4>
                                <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
                            </div>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                            <i className="ri-command-line"></i>
                            <div>
                                <h4>Molestiae dolor</h4>
                                <p>Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte</p>
                            </div>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                            <i className="ri-radar-line"></i>
                            <div>
                                <h4>Explicabo consectetur</h4>
                                <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id="services" className="services">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                    <h2>Services</h2>
                    <p>Veritatis et dolores facere numquam et praesentium</p>
                    </header>
                    <div className="row gy-4">
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="service-box blue">
                        <i className="ri-discuss-line icon"></i>
                        <h3>Nesciunt Mete</h3>
                        <p>Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.</p>
                        <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="service-box orange">
                        <i className="ri-discuss-line icon"></i>
                        <h3>Eosle Commodi</h3>
                        <p>Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem.</p>
                        <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="service-box green">
                        <i className="ri-discuss-line icon"></i>
                        <h3>Ledo Markt</h3>
                        <p>Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti.</p>
                        <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div className="service-box red">
                        <i className="ri-discuss-line icon"></i>
                        <h3>Asperiores Commodi</h3>
                        <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
                        <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div className="service-box purple">
                        <i className="ri-discuss-line icon"></i>
                        <h3>Velit Doloremque.</h3>
                        <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                        <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <div className="service-box pink">
                        <i className="ri-discuss-line icon"></i>
                        <h3>Dolori Architecto</h3>
                        <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
                        <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id="recent-blog-posts" className="recent-blog-posts">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                    <h2>Blog</h2>
                    <p>Recent posts form our Blog</p>
                    </header>
                    <div className="row">
                    <div className="col-lg-4">
                        <div className="post-box">
                        <div className="post-img"><img src={Blog1} className="img-fluid" alt=""/></div>
                        <span className="post-date">Tue, September 15</span>
                        <h3 className="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
                        <a href="blog-single.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="post-box">
                        <div className="post-img"><img src={Blog2} className="img-fluid" alt=""/></div>
                        <span className="post-date">Fri, August 28</span>
                        <h3 className="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
                        <a href="blog-single.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="post-box">
                        <div className="post-img"><img src={Blog3} className="img-fluid" alt=""/></div>
                        <span className="post-date">Mon, July 11</span>
                        <h3 className="post-title">Quia assumenda est et veritatis aut quae</h3>
                        <a href="blog-single.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <Team />
            <Client/>
            <Testimonial />
            <FAQ />
            <Footer />
        </div>
    );
};

export default Home;