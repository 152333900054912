import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import coursesimg from "../../assets/Image/21004063.jpg";
import Blog1 from '../../assets/blog/blog-1.jpg';
import Blog2 from '../../assets/blog/blog-2.jpg';
import Blog3 from '../../assets/blog/blog-3.jpg';
import "./Courses.scss";
const Courses = () => {
    return (
        <>
            <Header />
            <section className="mbr-fullscreen" id="animateHeader17-7b">
                <div className="container coursescontainer">
                    <div className="row align-items-center">
                        <div className="col-md-6 align-left img-block py-md-0 pb-4">
                            <div className="mbr-figure">
                                <img src={coursesimg} alt="ctf" title="" className="coursesimg"/>
                            </div>
                        </div>
                        <div className="mbr-white col-md-6 py-md-0 pt-2">
                            <h4 className="mbr-section-subtitle mbr-fonts-style align-left pb-2 display-5"><strong>Capture The Flag</strong></h4>
                            <h1 className="mbr-section-title mbr-white mbr-bold mbr-fonts-style align-left display-1">Start Hacking</h1>
                            <p className="mbr-text pb-3 mbr-fonts-style mbr-white align-left">
                                From challenges (web, crypto, reversing, forensics, etc.) to full-pwn machines and AD labs, it's all here! Join a public CTF or organize one for your team, event, conference, university, or company.</p>
                            <div className="mbr-section-btn"><a herf="index.html" className="btn btn-lg btn-primary display-4">Start Now</a></div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="recent-blog-posts" className="recent-blog-posts">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>Blog</h2>
                        <p>Recent posts form our Blog</p>
                    </header>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="post-box">
                                <div className="post-img"><img src={Blog1} className="img-fluid" alt="" /></div>
                                <span className="post-date">Tue, September 15</span>
                                <h3 className="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
                                <a href="blog-single.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="post-box">
                                <div className="post-img"><img src={Blog2} className="img-fluid" alt="" /></div>
                                <span className="post-date">Fri, August 28</span>
                                <h3 className="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
                                <a href="blog-single.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="post-box">
                                <div className="post-img"><img src={Blog3} className="img-fluid" alt="" /></div>
                                <span className="post-date">Mon, July 11</span>
                                <h3 className="post-title">Quia assumenda est et veritatis aut quae</h3>
                                <a href="blog-single.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Courses;