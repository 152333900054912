import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";

const Forum = () => {
    return (
        <>
            <Header />
            <section className="container mt-5" id="animateFooter8-4m">
                <div className="container slideInUp 1 text-center">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="plan-title mbr-section-title mbr-fonts-style display-3">Forums</h1>
                    </div>
                    <div className="row social-links mt-3 h4">
                        <ul className="d-flex justify-content-center align-items-center">
                            <li className='evemt-li'>
                                <a href="twitter" className="twitter"><i className="bi bi-twitter"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="facebook" className="facebook"><i className="bi bi-facebook"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="instagram" className="instagram"><i className="bi bi-instagram"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="linkedin" className="linkedin"><i className="bi bi-linkedin"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a className="icon-transition" href="/">
                                    <span className="mbr-iconfont socicon-rss socicon"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="row"></div>
                </div>
            </section>
            <section className="container mt-5" id="animateFooter8-4m">
                <div className="container slideInUp 1 text-center">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="plan-title mbr-section-title mbr-fonts-style display-1">Comming Soon! </h1>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Forum;