import './events.scss';
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import "./events.scss";
const Events = () => {
    return (
        <>
            <Header />

            <section className="container mt-5" id="animateFooter8-4m">
                <div className="container slideInUp 1 text-center">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="plan-title mbr-section-title mbr-fonts-style display-3">
                            Hacking Events,<br />CTF Challenges &amp;<br />AttackBox Conference
                        </h1>
                    </div>
                    <div className="row social-links mt-3 h4">
                        <ul className="d-flex justify-content-center align-items-center">
                            <li className='evemt-li'>
                                <a href="twitter" className="twitter"><i className="bi bi-twitter"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="facebook" className="facebook"><i className="bi bi-facebook"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="instagram" className="instagram"><i className="bi bi-instagram"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a href="linkedin" className="linkedin"><i className="bi bi-linkedin"></i></a>
                            </li>
                            <li className='evemt-li'>
                                <a className="icon-transition" href="/">
                                    <span className="mbr-iconfont socicon-rss socicon" ></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="row"></div>
                </div>
            </section>

            <section className="container" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="eventbox">
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/7e90gBu4pas?autoplay=1&mute=1&controls=0&loop=1&playlist=7e90gBu4pas" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 py-5 m-auto align-left">
                            <h3 className="mbr-section-subtitle pb-2 mbr-fonts-style display-7">
                                Upcoming Campaign</h3>
                            <h2 className="mbr-section-title mbr-bold pb-1 mbr-fonts-style display-4">We Keep Our Promises</h2>


                            <p className="mbr-text pb-3 mbr-fonts-style display-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                                accumsan sodales sapien id fermentum. Donec ante felis, ullamcorper eu lacus eu, rhoncus venenatis
                                nunc. Vivamus bibendum posuere dolor.</p>
                            <h4 className="coutn-title pb-2 mbr-fonts-style display-7">Elections Start Time:</h4>
                            <div className="countdown-cont align-left">
                                <div className="daysCountdown col-xs-3 col-sm-3 col-md-3" title="Days"></div>
                                <div className="hoursCountdown col-xs-3 col-sm-3 col-md-3" title="Hours"></div>
                                <div className="minutesCountdown col-xs-3 col-sm-3 col-md-3" title="Minutes"></div>
                                <div className="secondsCountdown col-xs-3 col-sm-3 col-md-3" title="Seconds"></div>
                                <div className="countdown pt-2" data-due-date="2022/01/01">
                                </div>
                            </div>
                            <div className="mbr-section-btn"><a className="btn btn-sm btn-primary display-4" href="/">Register Now</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="animateHeader15 extHeader cid-thLVX5FVSK" id="animateHeader15-84">
                <div className="container">
                    <div className="row align-items-center justify-content-center content-row">
                        <div className="content-block col-md-12 col-lg-6">
                            <div className="text-content">
                                <div className="media-container-row">
                                    <div className="col-md-12 ">
                                        <h2 className="mb-4 mbr-fonts-style mbr-section-title mbr-black display-4"><strong>Hacking</strong><br /><strong>Conference</strong><br /></h2>

                                        <p className="mb-4 mbr-fonts-style mbr-black display-7">Include multiple pages in one project. With the intuitive interface and all necessary blocks, even beginners can create a good-looking website.</p>
                                        <div className="mbr-section-btn"><a className="btn btn-sm btn-primary display-4" href="/">Register Now</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="mbr-figure"><iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/iM-NestDPNQ?rel=0&amp;amp;mute=1&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playlist=iM-NestDPNQ" width="620" height="400" frameborder="0" allowfullscreen title='video player'></iframe></div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="extFooter" id="animateFooter8-85">
                <div className="container">
                    <div className="col-md-12 col-sm-12 ">
                        <h1 className="plan-title fw-bold mbr-section-title mbr-fonts-style display-5">Upcoming Events</h1>
                    </div>
                    <div>
                        <table className="table">
                            <thead className="bg-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Position</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt="" className="rounded-circle user-icon" />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">John Doe</p>
                                                <p className="text-muted mb-0">john.doe@gmail.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">Software engineer</p>
                                        <p className="text-muted mb-0">IT department</p>
                                    </td>
                                    <td>
                                        <span className="badge badge-success rounded-pill d-inline">Active</span>
                                    </td>
                                    <td>Senior</td>
                                    <td>
                                        <button type="button" className="btn btn-link btn-sm btn-rounded">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img src="https://mdbootstrap.com/img/new/avatars/6.jpg" className="rounded-circle user-icon" alt="" />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">Alex Ray</p>
                                                <p className="text-muted mb-0">alex.ray@gmail.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">Consultant</p>
                                        <p className="text-muted mb-0">Finance</p>
                                    </td>
                                    <td>
                                        <span className="badge badge-primary rounded-pill d-inline">Onboarding</span>
                                    </td>
                                    <td>Junior</td>
                                    <td>
                                        <button type="button" className="btn btn-link btn-rounded btn-sm fw-bold" data-mdb-ripple-color="dark">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img src="https://mdbootstrap.com/img/new/avatars/7.jpg" className="rounded-circle user-icon" alt="" />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">Kate Hunington</p>
                                                <p className="text-muted mb-0">kate.hunington@gmail.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">Designer</p>
                                        <p className="text-muted mb-0">UI/UX</p>
                                    </td>
                                    <td>
                                        <span className="badge badge-warning rounded-pill d-inline">Awaiting</span>
                                    </td>
                                    <td>Senior</td>
                                    <td>
                                        <button type="button" className="btn btn-link btn-rounded btn-sm fw-bold" data-mdb-ripple-color="dark">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section className="animateContent5 extContent cid-thLU53y2jn" id="animateContent5-82">
                <div className="container slideInUp scrollanimation animate__animated animate__delay-0s animate__duration-0s animate__slower animate__repeat-1">
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="content-wrapper align-left">

                                <h1 className="mbr-section-title mbr-semibold mbr-white pb-4 mbr-fonts-style display-3">Want to Host<br />CTF Challenge<br />or Hacking Event <br />on Our Platform?</h1>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="content-wrapper align-left">
                                <p className="mbr-text mbr-white mbr-regular mbr-white mbr-fonts-style pt-4 h4">Lorem ipsum dolor sit amet,
                                    consectetur adipiscing elit. Aliquam tortor purus, suscipit a accumsan quis, blandit a dolor.
                                    Morbi quis purus at ipsum tristique varius.</p>
                                <div className="mbr-section-btn pt-4"><a className="btn btn-lg btn-primary display-6" href="/"><span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>READ MORE</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Events;