import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import "./CTFs.scss";
import flag from "../../assets/Image/red-flag-351x340.png";
import ctf from '../../assets/Image/ctf.png';

const CTFs = () => {
    return (
        <>
            <Header />
            <section className="animateHeader17 extHeader cid-thLdt20yWi mbr-fullscreen" id="animateHeader17-7b">
                <div className="container ctf">
                    <div className="row align-items-center">
                        <div className="col-md-6 align-left img-block py-md-0 pb-4">
                            <div className="mbr-figure">
                                <img src={flag} alt="ctf" title="" />
                            </div>
                        </div>
                        <div className="mbr-white col-md-6 py-md-0 pt-2">
                            <h4 className="mbr-section-subtitle mbr-fonts-style align-left pb-2 display-5"><strong>Capture The Flag</strong></h4>
                            <h1 className="mbr-section-title mbr-white mbr-bold mbr-fonts-style align-left display-1">Start Hacking</h1>
                            <p className="mbr-text pb-3 mbr-fonts-style mbr-white align-left">
                                From challenges (web, crypto, reversing, forensics, etc.) to full-pwn machines and AD labs, it's all here! Join a public CTF or organize one for your team, event, conference, university, or company.</p>
                            <div className="mbr-section-btn"><a herf="/" className="btn btn-lg btn-primary display-4">Start Now</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                <h1>Our Range</h1>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 1"/>
                                <div className="card-body">
                                    <h5 className="card-title">CTF Box 1</h5>
                                    <p className="card-text">This is a description of CTF Box 1.</p>
                                    <a href="/" className="btn btn-primary">Learn More</a>
                                </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 2"/>
                                <div className="card-body">
                                    <h5 className="card-title">CTF Box 2</h5>
                                    <p className="card-text">This is a description of CTF Box 2.</p>
                                    <a href="/" className="btn btn-primary">Learn More</a>
                                </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 3"/>
                                <div className="card-body">
                                    <h5 className="card-title">CTF Box 3</h5>
                                    <p className="card-text">This is a description of CTF Box 3.</p>
                                    <a href="/" className="btn btn-primary">Learn More</a>
                                </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4"/>
                                <div className="card-body">
                                    <h5 className="card-title">CTF Box 4</h5>
                                    <p className="card-text">This is a description of CTF Box 4.</p>
                                    <a href="/" className="btn btn-primary">Learn More</a>
                                </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 3" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 3</h5>
                                <p className="card-text">This is a description of CTF Box 3.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 3" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 3</h5>
                                <p className="card-text">This is a description of CTF Box 3.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 3" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 3</h5>
                                <p className="card-text">This is a description of CTF Box 3.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className="card">
                            <img src={ctf} className="card-img-top img-fluid" alt="CTF Box 4" />
                            <div className="card-body">
                                <h5 className="card-title">CTF Box 4</h5>
                                <p className="card-text">This is a description of CTF Box 4.</p>
                                <a href="/" className="btn btn-primary">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>


            <Footer />
        </>
    );
};

export default CTFs;